<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" status="danger" role="alert" closable (close)="errors = []">
  Oh snap! {{ errors[0] }}
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" status="success"  role="alert" closable (close)="messages = []">
    Hooray! {{ messages[0] }}
</nb-alert>

<div class="row resetPass-section">
  <div class="login-form-wrapper col-xl-4 col-lg-8 col-md-8 col-sm-8 ml-auto mr-auto">
    <div class="login-heading text-center">
      <h1 id="title" class="login-title">Change password</h1>
      <p class="login-subtitle">Please set a new password</p>
    </div>

    <form (ngSubmit)="resetPass()" #resetPassForm="ngForm" aria-labelledby="title">

      <div class="form-control-group">
        <div class="input-container input-text">
          <img src="assets/images/icons/lock.png" class="img-fluid input-icon">
          <span
            [class]="_new_show ? 'fa fa-eye input-icon rightside-icon' : 'fa fa-eye-slash input-icon rightside-icon'"
            (click)="togglepwdType('new password')"></span>
          <div class="floating-label">
            <input class="floating-input" nbInput [(ngModel)]="user.password" #password="ngModel" [type]="_new_pwdType"
              id="input-password" name="password" placeholder=" " autofocus fullWidth
              [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''" [pattern]="pattern" required
              [attr.aria-invalid]="password.invalid && password.touched ? true : null">
            <label class="label custom-label" for="input-password">New Password:</label>
          </div>
          <ng-container *ngIf="password.invalid && password.touched">
            <p class="error-message" *ngIf="password.errors?.required">
              Password is required!
            </p>
            <p class="error-message" *ngIf="password.errors?.pattern">
              Pattern does not mtach. Password should contains 8 to 50 characters ,
              atleast one upper case , one lower case , one special charater and one
              numeric
            </p>
          </ng-container>
        </div>
      </div>

      <div class="form-control-group ">
        <div class="input-container input-text">
          <img src="assets/images/icons/lock.png" class="img-fluid input-icon">
          <span [class]="_confirm_show ? 'fa fa-eye input-icon rightside-icon' : 'fa fa-eye-slash input-icon rightside-icon'"
            (click)="togglepwdType('confirm-password')"></span>
          <div class="floating-label">
            <input class="floating-input" nbInput [(ngModel)]="user.confirmPassword" #rePass="ngModel"
              id="input-re-password" name="rePass" [type]="_confirm_pwdType" placeholder=" " fullWidth [status]="rePass.touched
               ? (rePass.invalid || password.value != rePass.value ? 'danger' : 'success')
               : ''" required
              [attr.aria-invalid]="rePass.invalid && rePass.touched ? true : null">
            <label class="label custom-label" for="input-re-password">Confirm Password:</label>
          </div>
          <ng-container *ngIf="rePass.touched">
            <p class="error-message" *ngIf="rePass.invalid && rePass.errors?.required">
              Password confirmation is required!
            </p>
            <p class="error-message" *ngIf="password.value != rePass.value && !rePass.errors?.required">
              Password does not match the confirm password.
            </p>
          </ng-container>
        </div>
      </div>

      <div class="row">
        <div class="col text-center">
          <button class="submit btn btn-lg login-button" fullWidth status="success"
            [disabled]="submitted || !resetPassForm.valid || password.value != rePass.value"
            [class.btn-pulse]="submitted">
            <span class="login-text">Change password</span>
          </button>
        </div>
      </div>

    </form>

    <section class="another-action text-center mt-4" aria-label="Sign in or sign up">
      <p class="sign-in-text">
        <a class="text-link left" routerLink="../login">Back to Log In</a>
        <a routerLink="../register" class="text-link right">Register</a>
      </p>
    </section>
  </div>
</div>
