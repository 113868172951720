import { APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, Injectable, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AutoLogoutService } from './auto-logout.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import {
    NbAuthModule,
    NbPasswordAuthStrategy,
    NbAuthJWTToken,
    NbOAuth2AuthStrategy,
    NbOAuth2ResponseType,
} from '@nebular/auth';
import {
    NbAlertModule,
    NbButtonModule,
    NbCheckboxModule,
    NbInputModule,
    NbStepperModule,
    NbSelectModule,
} from '@nebular/theme';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './@theme/theme.module';
import { NbDialogModule, NbDatepickerModule } from '@nebular/theme';
import { AuthGuard, LoginGuard } from './auth/auth.guard';
import { NgSelectModule } from '@ng-select/ng-select';
import { environment } from '../environments/environment';
import { httpInterceptorProviders } from './interceptors/index';
import * as Sentry from '@sentry/browser';
import { Globals } from './global';
import { RewriteFrames } from '@sentry/integrations';
import { AnalyticsService } from './@core/utils/analytics.service';
import { CryptoService } from './crypto.service';
import {
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule,
    NgxUiLoaderConfig,
    SPINNER,
} from 'ngx-ui-loader';
import { CookieService } from 'ngx-cookie-service';
import { RequestCache, RequestCacheWithMap } from './request-cache.service';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { UpdatesNotificationComponent } from './updates-notification/updates-notification.component';
import { NgxRegisterCodeComponent } from './auth/register-code/register-code.component';
import { NgxLoginComponent } from './auth/login/login.component';
import { NgxLogoutComponent } from './auth/logout.component';
import { NbOAuth2LoginComponent } from './auth/nb-oauth2-login/nb-oauth2-login.component';
import { NbOAuth2CallbackComponent } from './auth/nb-oauth2-callback/nb-oauth2-callback.component';
import { RequestPasswordComponent } from './auth/request-password/request-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { DataService } from './admin/data.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

const apiUrl = `${environment.apiUrl}/`;

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {
        Sentry.init({
            dsn: 'https://e5a3ffe929c14c59bc5fb2df2dc8ae18@o312670.ingest.sentry.io/5198373',
            environment: environment.site_state,
            integrations: [
                new RewriteFrames(),
            ],
        });
    }
    handleError(error) {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        } else {
            Sentry.captureException(error.originalError || error);
            console.error(error);
        }
    }
}

export function getErrorHandler(): ErrorHandler {
    if (environment.production) {
        return new SentryErrorHandler();
    } else {
        return new ErrorHandler();
    }
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    bgsColor: '#5a53ff',
    bgsPosition: 'bottom-left',
    bgsType: SPINNER.ballScaleMultiple, // background spinner type
    masterLoaderId: 'loader-01',
    blur: 7,
    logoUrl: 'assets/images/icons/use-on-white.png',
    logoSize: 68,
    fgsColor: '#5a53ff',
    fgsType: SPINNER.ballScaleMultiple, // foreground spinner type
    pbColor: '#5a53ff',
    pbThickness: 3, // progress bar thickness,
    overlayColor: 'rgba(210, 210, 210, 0.8)',
};

const formSetting: any = {
    redirectDelay: 0,
};

@NgModule({
    declarations: [
        AppComponent,
        NgxLoginComponent,
        NgxLogoutComponent,
        NgxRegisterCodeComponent,
        NbOAuth2CallbackComponent,
        NbOAuth2LoginComponent,
        RequestPasswordComponent,
        ResetPasswordComponent,
        UpdatesNotificationComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        NgSelectModule,
        RecaptchaV3Module,
        NbDialogModule.forRoot(),
        CoreModule.forRoot(),
        ThemeModule.forRoot(),
        NbDatepickerModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        NbAuthModule.forRoot({
            strategies: [
                NbPasswordAuthStrategy.setup({
                    name: 'email',
                    baseEndpoint: apiUrl,
                    login: {
                        endpoint: 'users/login',
                        method: 'post',
                        requireValidToken: false,
                        redirect: {
                            success: '/admin/dashboard',
                            failure: null,
                        },
                        defaultErrors: ['Email Address/Password combination is not correct, please try again..'],
                        defaultMessages: ['You have been successfully logged in.'],
                    },
                    requestPass: {
                        endpoint: 'users/requestPassword',
                        method: 'post',
                        redirect: {
                            success: '/login',
                            failure: null,
                        },
                        defaultErrors: ['Email does not exist, Please try again.'],
                        defaultMessages: ['You will receive an email with a link to reset your password.'],
                    },
                    resetPass: {
                        endpoint: 'users/resetPassword',
                        method: 'put',
                        redirect: {
                            success: '/login',
                            failure: '/login',
                        },
                        resetPasswordTokenKey: 'token',
                        defaultErrors: ['Something went wrong, please try again.'],
                        defaultMessages: ['Your password has been successfully changed.'],
                    },
                    logout: {
                        endpoint: 'users/logout',
                        method: 'post',
                    },
                    token: {
                        class: NbAuthJWTToken,
                        key: 'token', // this parameter tells where to look for the token
                    },
                    errors: {
                        getter: errorGetter,
                    },
                }),
                NbOAuth2AuthStrategy.setup({
                    name: 'google',
                    clientId: `${environment.google.clientId}`,
                    clientSecret: `${environment.google.clientSecret}`,
                    authorize: {
                        endpoint: `${environment.google.endpoint}`,
                        responseType: NbOAuth2ResponseType.TOKEN,
                        scope: `${environment.google.scope}`,
                        redirectUri: `${environment.google.redirectUri}`,
                    },
                }),
            ],
            forms: {
                login: formSetting,
                register: formSetting,
                requestPassword: formSetting,
                resetPassword: formSetting,
                logout: formSetting,
                validation: {
                    password: {
                        required: true,
                        minLength: 8,
                        maxLength: 50,
                    },
                },
            },
        }),
        NbEvaIconsModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        NgxUiLoaderHttpModule.forRoot({
            exclude: [apiUrl + '/paymentStatus', '/'],
        }),
        FormsModule,
        ReactiveFormsModule,
        NbAlertModule,
        NbInputModule,
        NbButtonModule,
        NbCheckboxModule,
        NbStepperModule,
        NbSelectModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.captchaSiteKey },
        { provide: APP_BASE_HREF, useValue: '/' },
        AuthGuard,
        LoginGuard,
        Globals,
        AutoLogoutService,
        AnalyticsService,
        CookieService,
        CryptoService,
        { provide: RequestCache, useClass: RequestCacheWithMap },
        httpInterceptorProviders,
        { provide: ErrorHandler, useFactory: getErrorHandler },
        DataService,
    ],
})

export class AppModule { }

export function errorGetter(module, res, options) {
    return !res.error.success ? res.error.error : options[module].defaultErrors;
}
