import {
    Component,
    Output,
    EventEmitter,
    Inject,
    ChangeDetectorRef,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NbRegisterComponent, NB_AUTH_OPTIONS, NbAuthService } from '@nebular/auth';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NgForm } from '@angular/forms';
import { DataService } from '../../admin/data.service';
import { environment } from '../../../environments/environment';
import { Globals } from '../../global';
@Component({
    selector: 'ngx-register-code',
    templateUrl: './register-code.component.html',
    styleUrls: ['./register-code.component.scss'],
})
export class NgxRegisterCodeComponent extends NbRegisterComponent {
    @ViewChild('pform', { static: false }) form: NgForm;
    reg_code: any;
    loading = false;
    user: any;
    error: boolean = false;
    success: boolean = false;
    subscription: Subscription;
    selectedIndex: Number = 0;
    message: any;
    error_msg: string = '';
    success_msg: string = '';
    userData: any;
    brand_website: string = environment.brand_website;
    @Output() messageEvent = new EventEmitter<boolean>();
    isFocus: boolean = false;
    isLength: boolean;
    isNumber: boolean;
    isLowerCase: boolean;
    isUpperCase: boolean;
    isSpecialChar: boolean;
    pass_pattern: boolean;
    email: any;
    _show: boolean = false;
    _pwdType: string = 'password';
    clickedPasswordIcon: boolean = false;
    pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

    constructor(
        protected service: NbAuthService,
        @Inject(NB_AUTH_OPTIONS) protected options = {},
        protected router: Router,
        private api: ApiService,
        private route: ActivatedRoute,
        private recaptchaV3Service: ReCaptchaV3Service,
        protected cd: ChangeDetectorRef,
        private dataService: DataService,
        private globals: Globals,
    ) {
        super(service, options, cd, router);
        this.reg_code = this.route.snapshot.paramMap.get('reg_code');
        this.user['regCode'] = this.reg_code;
        this.globals.sidebarStatus(false);
        this.globals.headerStatus(true);
    }

    // Step 1 :: Verify Reg Code, Mobile & Send OTP
    onRegister() {
        this.loading = true;
        this.messageEvent.emit(this.loading);
        this.subscription = this.recaptchaV3Service
            .execute('registerUsingCode')
            .subscribe(token => this.onTokenGen(token));
    }

    onTokenGen(t) {
        this.user['recaptcha_token'] = t;
        if (!t) {
            alert('Recaptcha error');
        } else {
            this.verifyRegCode();
        }
    }

    verifyRegCode() {
        this.api.verifyRegCode(this.user).subscribe((data: { email: '', message: '' }) => {
            this.selectedIndex = 1;
            this.email = data.email;
            this.success_msg = data.message;
            setTimeout(() => {
                this.success_msg = '';
            }, 3000);
        }, err => {
            this.error_msg = err.error;
            setTimeout(() => {
                this.error_msg = '';
            }, 10000);
        });
    }

    // Step 2 :: Verify OTP
    onSubmit() {
        this.api.verifyOtp(this.user).subscribe((otp) => {
            if (otp['success']) this.selectedIndex = 2;
        }, err => {
            this.error_msg = err.error;
            setTimeout(() => {
                this.error_msg = '';
            }, 10000);
        });
    }

    enterPassword(form: NgForm) {

        const dataUser = {
            email: this.email,
            password: form.value['password'],
        };
        this.api.registerStageUser(dataUser).subscribe(data => {
            if (data['success']) {
                this.dataService.set_msg('You have been registered successfully, Please login to continue.', 'success');
                this.router.navigateByUrl('login');
            }
        }, err => {
            this.error_msg = err.error;
            setTimeout(() => {
                this.error_msg = '';
            }, 10000);
        });
    }

    onPasswordInput(e) {
        this.isFocus = true;
        this.isLength = (e.length >= 8);
        this.isNumber = /[0-9]/.test(e);
        this.isLowerCase = /[a-z]/.test(e);
        this.isUpperCase = /[A-Z]/.test(e);
        this.isSpecialChar = /[!@#*\$%\^&]/.test(e);
        this.pass_pattern = this.isLength && this.isNumber && this.isLowerCase && this.isUpperCase && this.isSpecialChar;
    }

    togglepwdType() {
        this._show = !this._show;
        this._pwdType = this._show ? 'text' : 'password';
    }

    OpenPasswordBox() {
        this.clickedPasswordIcon = !this.clickedPasswordIcon;
    }
}
