import { Injectable } from '@angular/core';

@Injectable()
export class DataService {
    private subj_id: any;
    private chapt_id: any;
    private set_screen: boolean;
    private data: any = [];
    private message: any = [];
    private studentResult_screen: boolean;
    private stageId: any;
    private active_date: any;
    constructor() { }

    public get_subj_id(): any {
        return this.subj_id;
    }

    public set_subj_id(subj_id: any): void {
        this.subj_id = subj_id;
    }

    public get_chapt_id(): any {
        return this.chapt_id;
    }

    public set_chapt_id(chapt_id: any): void {
        this.chapt_id = chapt_id;
    }

    public get_set_screen(): boolean {
        return this.set_screen;
    }

    public set_set_screen(set_screen: boolean): void {
        this.set_screen = set_screen;
    }

    public set_top_questions(viewMode: boolean, selectIndex: number): void {
        this.data = {
            viewMode: viewMode,
            selectIndex: selectIndex,
        };
    }

    public get_top_questions(): boolean {
        return this.data;
    }

    public set_msg(msg: any, type: any): void {
        this.message = {
            msg: msg,
            type: type,
        };
    }

    public get_msg(): void {
        return this.message;
    }

    public get_studentResult_screen(): boolean {
        return this.studentResult_screen;
    }

    public set_studentResult_screen(studentResult_screen: boolean): void {
        this.studentResult_screen = studentResult_screen;
    }

    public get_stageId(): boolean {
        return this.stageId;
    }

    public set_stageId(stageId: any): void {
        this.stageId = stageId;
    }

    public set_active_date(active_date: any): void {
        this.active_date = active_date;
    }

    public get_active_date(): boolean {
        return this.active_date;
    }
}
