<div class="header-container" [class.left]="position === 'normal'" [class.right]="position === 'inverse'">
  <div class="logo-containter" *ngIf="isAvailable">
    <a routerLink="/admin/dashboard">
      <div class="logo">
        <img src="assets/images/logo/slingshot-white-logo.png">
      </div>
    </a>
  </div>
  <div class="logo-containter" *ngIf="!isAvailable">
    <a routerLink="/admin/dashboard">
      <div class="logo" [ngClass]="{'login-logo': !isAvailable}">
        <img src="assets/images/logo/slingshot-white-logo.png">
      </div>
    </a>
  </div>
</div>
<div class="header-container" [ngClass]="{'header-wrapper': !isAvailable}">
  <div *ngIf="isAvailable">
    <nb-actions size="medium" [class.right]="position === 'normal'" [class.left]="position === 'inverse'">
      <nb-action [ngClass]="'header-icons border-0'">
        <img [nbContextMenu]="userMenu" nbContextMenuTag="context-menu" src="assets/images/icons/settings.png">
      </nb-action>
      <!-- <nb-action [ngClass]="'header-icons border-0'">
        <img [nbContextMenu]="helpMenu" src="assets/icons/help.png">
        </nb-action> -->
        <!-- <nb-action [ngClass]="'header-icons border-0'">
            <nb-badge class="notification-counts" status="danger" text="1"></nb-badge>
            <img src="assets/images/icons/notification-white.png">
        </nb-action> -->
      <nb-action [ngClass]="'header-icons border-0 shadow-divider'">
        <img src="assets/images/icons/vertical-shadow-white.png">
      </nb-action>
      <nb-action [ngClass]="'border-0 hide-on-mobile'">
        <div class="btn-wrap">
            <button class="create-btn" [routerLink]="['/admin/tests']">Create Test</button>
          </div>
      </nb-action>
    </nb-actions>
  </div>
</div>
