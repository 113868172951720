import { Component, OnDestroy } from '@angular/core';
import { NbAuthService, NbAuthOAuth2Token } from '@nebular/auth';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'ngx-nb-oauth2-login',
    template: `
    <div class='g-sign-in-button' *ngIf="!token" (click)="login()">
        <div class=content-wrapper>
            <div class='logo-wrapper'>
            </div>
            <span class='text-container'>
                <span>Sign in with Google</span>
            </span>
        </div>
    </div>`,
    styles: [`.btn{
        font-size:0.8rem;
      }
      *, *:before, *:after {
        box-sizing: border-box;
      }
      .g-sign-in-button {
        display: inline-block;
        width: 320px;
        height: 55px;
        background-color: #fff;
        color: #5a53ff;
        border-radius: 6px;
        border: 2px solid #5a53ff;
        text-transform: uppercase;
        cursor: pointer;
      }

      .g-sign-in-button .content-wrapper {
        height: 100%;
        width: 100%;
      }
      .g-sign-in-button img {
        width: 18px;
        height: 18px;
      }
      .g-sign-in-button .logo-wrapper {
        background: #5a53ff;
        width: 70px;
        height: 100%;
        display: inline-block;
        float:left;
        position: relative;
      }

      .g-sign-in-button .logo-wrapper::after {
        content: "g";
        position: absolute;
        left: 21px;
        top: -4px;
        width: 23px;
        height: 42px;
        font-family: FontAwesome;
        font-size: 36px;
        line-height: 1.31;
        color: #fff;
        text-transform: lowercase;
        font-weight: 100;
      }
      .g-sign-in-button .text-container {
        font-family: exo-2-semibold;
        font-size: 22px;
        font-weight: initial;
        line-height: 1.91;
        display: inline-block;
        text-align: center;
        vertical-align: sub;
      }`],
})
export class NbOAuth2LoginComponent implements OnDestroy {
    private destroy$ = new Subject<void>();
    token: NbAuthOAuth2Token;

    constructor(private authService: NbAuthService) { }

    login() {
        this.authService.authenticate('google')
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => { });
        }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
