import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { Injectable, NgZone } from '@angular/core';

const MINUTES_UNITL_AUTO_LOGOUT = 60; // in Minutes
const CHECK_INTERVALL = 1000; // in ms
const STORE_KEY = 'lastAction';

@Injectable()
export class AutoLogoutService {
  interval: NodeJS.Timeout;

  constructor(
    private auth: AuthService,
    private router: Router,
    private ngZone: NgZone,
  ) {
    this.check();
    this.initListener();
    this.initInterval();
    this.lastAction = Date.now();
  }

  get lastAction() {
    return parseInt(localStorage.getItem(STORE_KEY), 10);
  }

  set lastAction(value) {
    localStorage.setItem(STORE_KEY, value.toString());
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
    });
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      this.interval = setInterval(() => {
        this.check();
      }, CHECK_INTERVALL);
    });
  }

  reset() {
    this.lastAction = Date.now();
  }

  check() {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    if (!this.auth.isLoggedIn) {
      clearInterval(this.interval);
    }

    this.ngZone.run(() => {
      if (isTimeout) {
        clearInterval(this.interval);
        this.router.navigateByUrl('/logout');
      }
    });
  }
}
