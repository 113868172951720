import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbAuthService, NbAuthResult } from '@nebular/auth';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../api.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { DataService } from '../../admin/data.service';
import { Globals } from '../../global';

@Component({
    selector: 'ngx-nb-oauth2-callback',
    template: '',
})
export class NbOAuth2CallbackComponent implements OnDestroy, OnInit {
    private destroy$ = new Subject<void>();

    constructor(private authService: NbAuthService,
        private api: ApiService,
        private router: Router,
        private dataService: DataService,
        private globals: Globals) {
        this.authService.authenticate('google')
            .pipe(takeUntil(this.destroy$))
            .subscribe((authResult: NbAuthResult) => {
                this.getProfileData(authResult);
            });
    }

    getProfileData(authResult) {
        const t = authResult.response.access_token;
        this.api.getProfileDataGoogle(t).subscribe((g: { name: '', given_name: '', family_name: '', email: '', picture: '' }) => {
            const data = {
                name: g.name,
                first_name: g.given_name,
                last_name: g.family_name,
                email: g.email,
                picture: g.picture,
                token: t,
                user_type: 'professor',
            };

            this.api.googleAuth(data).subscribe((d) => {
                localStorage.setItem('google_email', d.user.email);
                this.router.navigate(['/login']);
            }, err => {
                localStorage.setItem('google_email', 'false');
                this.dataService.set_msg(err.error, 'danger');
                this.router.navigateByUrl('/login');
            });
        });
    }

    ngOnInit() {
        this.globals.sidebarStatus(false);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
