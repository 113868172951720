import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpRequest, HttpResponse,
    HttpInterceptor, HttpHandler,
} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { RequestCache } from '../request-cache.service';


@Injectable()
export class CachingInterceptor implements HttpInterceptor {
    constructor(private cache: RequestCache) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // continue if not cachable.
        if (!isCachable(req)) { return next.handle(req); }

        const cachedResponse = this.cache.get(req);
        if (!environment.production && window.navigator.onLine) {
            console.info(cachedResponse ? '[cached]' : '[caching]', req.urlWithParams);
        }

        // refresh-cache-if-online
        if (window.navigator.onLine) {
            const results$ = sendRequest(req, next, this.cache);
            return cachedResponse ?
                results$.pipe(startWith(cachedResponse)) :
                results$;
        }

        if (!environment.production && !window.navigator.onLine) {
            console.info('[cache]', req.urlWithParams, cachedResponse ? 'Serving from cache' : 'Nope');
        }

        // cache-or-fetch
        return cachedResponse ?
            of(cachedResponse) : sendRequest(req, next, this.cache);
    }
}


/** Is this request cachable? */
function isCachable(req: HttpRequest<any>) {
    // Only GET requests are cachable
    return req.method === 'GET';
}

/**
 * Get server response observable by sending request to `next()`.
 * Will add the response to the cache on the way out.
 */
function sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCache): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
        tap(event => {
            // There may be other events besides the response.
            if (event instanceof HttpResponse) {
                cache.put(req, event); // Update the cache.
            }
        }),
    );
}
