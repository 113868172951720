import { Component, OnDestroy } from '@angular/core';
import { delay, withLatestFrom, takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
    NbMediaBreakpoint,
    NbMediaBreakpointsService,
    NbMenuItem,
    NbMenuService,
    NbSidebarService,
    NbThemeService,
} from '@nebular/theme';

import { StateService } from '../../../@core/utils';
import { Globals } from '../../../global';
// TODO: move layouts into the framework
// TODO: Please rename the layout name from ngx-sample to something good
@Component({
    selector: 'ngx-structure-layout',
    styleUrls: ['./structure.layout.scss'],
    template: `
    <nb-layout [center]="layout.id === 'center-column'" windowMode
    style="-moz-user-select: none; -webkit-user-select: none;
    -ms-user-select:none; user-select:none;-o-user-select:none;" unselectable="on" >
        <nb-layout-header fixed *ngIf="globals.header_status && auth_status" class="loggedout-nav">
            <ngx-header [position]="sidebar.id === 'start' ? 'normal': 'inverse'"></ngx-header>
        </nb-layout-header>

        <nb-layout-header fixed *ngIf="globals.header_status && !auth_status">
            <ngx-header [position]="sidebar.id === 'start' ? 'normal': 'inverse'" ></ngx-header>
        </nb-layout-header>

        <nb-sidebar class="menu-sidebar" [tag]="sidebarTag"
        [responsive]="true" [compactedBreakpoints]="['xs', 'is', 'sm', 'md', 'lg']"
        [collapsedBreakpoints]="['xs', 'is', 'sm', 'md', 'lg']" *ngIf="sidebar_status ||globals.sidebar_status">
            <nb-menu [items]="items" tag="leftMenu"></nb-menu>
            <a routerLink="/admin/plan" (click)="unlockBtn()">
                <div class="unlock-btn-mobile">UPGRADE PLAN</div>
            </a>

            <button class="collapse-arrow" (click)="toggle()">
            <i class="fa fa-angle-left" aria-hidden="true"></i>
            <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
        </nb-sidebar>

        <nb-layout-column class="main-content p-0 m-0">
            <ng-content select="router-outlet"></ng-content>
        </nb-layout-column>

        <nb-layout-column start class="small" *ngIf="layout.id === 'two-column' || layout.id === 'three-column'">
            <nb-menu [items]="subMenu"></nb-menu>
        </nb-layout-column>

        <nb-layout-column class="small" *ngIf="layout.id === 'three-column'">
            <nb-menu [items]="subMenu"></nb-menu>
        </nb-layout-column>
    </nb-layout>
  `,
})
export class StructureLayoutComponent implements OnDestroy {
    subMenu: NbMenuItem[] = [
        {
            title: 'PAGE LEVEL MENU',
        },
    ];
    items = [
        {
            title: 'Dashboard',
            icon: 'menu-outline',
            link: '/admin/dashboard',
        },
        {
            title: 'Students',
            icon: 'person',
            link: '/admin/students',
        },
        {
            title: 'Batches',
            icon: 'people',
            link: '/admin/batches',
        },
        {
            title: 'Test Library',
            icon: 'book-open-outline',
            link: '/admin/test-library/live',
            expanded: true,
            children: [
                {
                    title: 'Live',
                    link: '/admin/test-library/live',
                },
                {
                    title: 'Drafts',
                    link: '/admin/test-library/draft',
                },
                {
                    title: 'Archived',
                    link: '/admin/test-library/archived',
                },
            ],
        },
        {
            title: 'Question Bank',
            icon: 'file-text-outline',
            link: '/admin/question-bank',
        },
    ];
    layout: any = {};
    sidebar: any = {};
    isSidebarAvailable: any;
    isLogginAvailable: any;
    private alive = true;

    currentTheme: string;
    sidebar_status: any = false;
    auth_status: boolean = false;

    constructor(protected stateService: StateService,
        protected menuService: NbMenuService,
        protected themeService: NbThemeService,
        protected bpService: NbMediaBreakpointsService,
        protected sidebarService: NbSidebarService,
        public globals: Globals,
        protected router: Router) {
        router.events.subscribe(() => {
            this.auth_status = !router.url.includes('admin');
        });
        this.stateService.onLayoutState()
            .pipe(takeWhile(() => this.alive))
            .subscribe((layout: string) => this.layout = layout);

        this.stateService.onSidebarState()
            .pipe(takeWhile(() => this.alive))
            .subscribe((sidebar: string) => {
                this.sidebar = sidebar;
            });

        const isBp = this.bpService.getByName('is');
        this.menuService.onItemSelect()
            .pipe(takeWhile(() => this.alive),
                withLatestFrom(this.themeService.onMediaQueryChange()),
                delay(20),
            )
            .subscribe(([_item, [_any, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {
                if (bpTo.width <= isBp.width) {
                    this.sidebarService.collapse('menu-sidebar');
                }
            });

        this.themeService.getJsTheme()
            .pipe(takeWhile(() => this.alive))
            .subscribe(theme => {
                this.currentTheme = theme.name;
            });
        this.sidebar_status = router.url.includes('admin');
    }

    toggle() {
        this.sidebarService.toggle(true, 'menu-sidebar');
    }

    toggleCompact() {
        this.sidebarService.toggle(true, 'right');
    }

    collapseMenu() {
        this.menuService.collapseAll('leftMenu');
    }

    ngOnDestroy() {
        this.alive = false;
    }

    unlockBtn() {
        // this.globals.changePlanButton(true);
    }
}

// TODO : Keep this safe untill all fixes done
// <nb-sidebar class="menu-sidebar"
// tag="menu-sidebar"
// responsive
// [end]="sidebar.id === 'end'">
// <ng-content select="nb-menu"></ng-content>
// </nb-sidebar>

// collapse
// <p (click)="collapseMenu()" class="collapse-all">Close all</p>

// toggle complete
// <button nbButton status="success" size="tiny" (click)="toggle()">Toggle</button>

// head
// <nb-layout-header fixed *ngIf="router.url !== '/admin/plan'">
//         <ngx-header [position]="sidebar.id === 'start' ? 'normal': 'inverse'"></ngx-header>
//       </nb-layout-header>
