<nb-alert *ngIf="_msg" [status]="msg_type" role="alert" closable (close)="onClose()">
    {{ _msg }}
</nb-alert>

<nb-alert *ngIf="showMessages.error && errors?.length" status="danger" role="alert" closable (close)="errors = []">
    Oh snap! {{ errors[0] }}
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length" status="success" role="alert" closable (close)="messages = []">
    Hooray! {{ messages[0] }}
</nb-alert>

<div class="login-section" [ngClass]="google_email ? 'wrapper-1' : 'wrapper-2'">
    <div class="login-form-wrapper col-xl-5 col-lg-6 col-md-6 col-sm-6 ml-auto mr-auto">
        <div class="login-heading text-center">
            <h1 id="title" class="login-title">Welcome to Slingshot Institutes!</h1>
            <p class="login-subtitle">Please fill details below to Login</p>
        </div>
    </div>

    <div class="login-form-wrapper col-xl-3 col-lg-5 col-md-6 col-sm-6 ml-auto mr-auto">
        <form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">
            <div class="form-control-group">
                <div class="input-container input-text">
                    <img src="assets/images/icons/email.png" class="img-fluid input-icon">
                    <div class="floating-label">
                        <input class="floating-input" nbInput [(ngModel)]="user.email" #email="ngModel" id="input-email" name="email" pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}" placeholder=" " fullWidth [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'" [required]="getConfigValue('forms.validation.email.required')" [attr.aria-invalid]="email.invalid && email.touched ? true : null">

                        <!-- hiddden fields -->
                        <input type="hidden" [(ngModel)]="user.user_type" #userType="ngModel" id="user_type"
                            name="userType" required *ngIf="user.user_type">
                        <input type="hidden" [(ngModel)]="user.google_login_status" #googleUser="ngModel"
                            id="google_email" name="googleUser" required *ngIf="user.google_email">

                        <label class="label custom-label" for="input-email">Email address:</label>
                    </div>
                    <ng-container *ngIf="email.invalid && email.touched">
                        <p class="error-message" *ngIf="email.errors?.required">
                            Email is required!
                        </p>
                        <p class="error-message" *ngIf="email.errors?.pattern">
                            Email should be the real one!
                        </p>
                    </ng-container>
                </div>
            </div>

            <div class="form-control-group login-password">
                <div class="input-container input-text">
                    <img src="assets/images/icons/lock.png" class="img-fluid input-icon">
                    <span [class]="_show ? 'fa fa-eye input-icon rightside-icon' : 'fa fa-eye-slash input-icon rightside-icon'" (click)="togglepwdType()"></span>
                    <div class="floating-label">
                        <input class="floating-input" nbInput fullWidth [(ngModel)]="user.password" #password="ngModel" name="password" [type]="_pwdType" id="input-password" placeholder=" " [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'" [required]="!user.google_login_status" minlength="8" maxlength="50" [attr.aria-invalid]="password.invalid && password.touched ? true : null">
                        <label class="label custom-label" for="input-password">Password:</label>
                    </div>
                    <ng-container *ngIf="password.invalid && password.touched ">
                        <p class="error-message" *ngIf="password.errors?.required">
                            Password is required!
                        </p>
                        <p class="error-message" *ngIf="password.errors?.minlength || password.errors?.maxlength">
                            Password should contains from 8 to 50 characters
                        </p>
                    </ng-container>
                </div>
            </div>

            <div class="form-control-group accept-group">
                <nb-checkbox class="remember-text" name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">
                    Remember me
                </nb-checkbox>
                <a class="forgot-password float-right" routerLink="../request-password">Forgot Password?</a>
            </div>

            <div class="text-center login-btn-wrap">
                <button #logBut class="submit btn btn-lg login-button" fullWidth status="success" [disabled]="!form.valid">
                    <span class="login-text">Log In</span>
                </button>
            </div>
        </form>

        <section class="another-action text-center" aria-label="Register">
            <h5>OR</h5>
            <h5 class="text-center p-0 m-0">
                <ngx-nb-oauth2-login></ngx-nb-oauth2-login>
            </h5>
        </section>
    </div>
</div>
