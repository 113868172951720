import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NbLoginComponent, NB_AUTH_OPTIONS, NbAuthService } from '@nebular/auth';

import { Router } from '@angular/router';
import { DataService } from '../../admin/data.service';
import { CookieService } from 'ngx-cookie-service';
import { Globals } from '../../global';
@Component({
    selector: 'ngx-login',
    templateUrl: './login.component.html',
    styleUrls: ['login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NgxLoginComponent extends NbLoginComponent implements OnInit {
    _show: boolean = false;
    _pwdType = 'password';
    _msg: any = null;
    msg_type: any;
    google_email: string;
    @ViewChild('logBut', { static: true }) logBut: ElementRef<HTMLElement>;

    constructor(
        protected service: NbAuthService,
        @Inject(NB_AUTH_OPTIONS) protected options = {},
        protected cd: ChangeDetectorRef,
        protected router: Router,
        private dataService: DataService,
        private cookieService: CookieService,
        private globals: Globals,
    ) {
        super(service, options, cd, router);
        this._msg = this.dataService.get_msg()['msg'];
        this.msg_type = this.dataService.get_msg()['type'];
        this.user.rememberMe = this.cookieService.get('rememberMe');
        this.user.user_type = 'professor';

        if (localStorage.getItem('google_email') && localStorage.getItem('google_email') !== 'false') {
            this.user.google_login_status = true;
            this.user.google_email = localStorage.getItem('google_email');
        }
    }

    ngOnInit() {
        this.globals.sidebarStatus(false);
        this.globals.headerStatus(true);
        if (this._msg) {
            setTimeout(() => (this.onClose()), 500);
        }

        if (localStorage.getItem('google_email') && localStorage.getItem('google_email') !== 'false') {
            this.triggerFalseClick();
        } else {
            localStorage.clear();
        }
    }

    togglepwdType() {
        this._show = !this._show;
        this._pwdType = this._show ? 'text' : 'password';
    }

    onClose() {
        this._msg = null;
        this.dataService.set_msg(null, null);
    }

    triggerFalseClick() {
        const el: HTMLElement = this.logBut.nativeElement;
        el.click();
        localStorage.clear();
    }
}
