import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { CryptoService } from './crypto.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
const apiUrl = `${environment.apiUrl}`;
const googleUrl = 'https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=';


@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(private http: HttpClient,
        private cryptopService: CryptoService,
    ) { }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(
                `Went ${error.status}`,
                error.error,
            );
        }
        return throwError(error.error);
    }

    private extractData(res: Response) {
        const body = res;
        return body || {};
    }

    getallExamsData() {
        return this.getReq(`${apiUrl}/allExams/`);
    }

    getbatchesList() {
        return this.getReq(`${apiUrl}/batchesList`);
    }

    getBatchById(batch_id) {
        const enc_batch_id = this.cryptopService.setParam(batch_id);
        return this.getReq(`${apiUrl}/batcheById/${enc_batch_id}`);
    }

    createBatch(data) {
        return this.postReq(`${apiUrl}/createBatch`, data);
    }

    editBatch(data) {
        return this.postReq(`${apiUrl}/editBatch`, data);
    }

    removeBatch(data) {
        return this.deleteReq(`${apiUrl}/removeBatch`, data);
    }

    verifyToken(data) {
        return this.postReq(`${apiUrl}/users/tokenVerify`, data);
    }

    googleAuth(data) {
        return this.postReq(`${apiUrl}/users/google/`, data);
    }

    registerStageUser(data) {
        return this.postReq(`${apiUrl}/users/registerStageUser/`, data);
    }

    getProfileDataGoogle(token: string) {
        return this.getReq(`${googleUrl}${token}`);
    }

    verifyRegCode(data) {
        return this.postReq(`${apiUrl}/verifyRegCode/`, data);
    }

    verifyOtp(data) {
        return this.postReq(`${apiUrl}/verifyOtp/`, data);
    }

    getCity() {
        return this.getReq(`${apiUrl}/city`);
    }

    getProfDashboardData() {
        return this.getReq(`${apiUrl}/getProfDashboardData`);
    }

    getTopStudentsList(data) {
        return this.postReq(`${apiUrl}/getTopStudentsList/`, data);
    }

    getStudentList(batch_id, exam_id, search_value = '') {
        const enc_batch_id = this.cryptopService.setParam(batch_id);
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.getReq(`${apiUrl}/studentList/${enc_batch_id}/${enc_exam_id}?search=${search_value}`);
    }

    getChapterList(exam, subject) {
        const enc_exam = this.cryptopService.setParam(exam);
        const enc_subject = this.cryptopService.setParam(subject);
        return this.getReq(`${apiUrl}/chapterList/${enc_exam}/${enc_subject}`);
    }

    getAllChapter(exam, subject) {
        const enc_exam = this.cryptopService.setParam(exam);
        const enc_subject = this.cryptopService.setParam(subject);
        return this.getReq(`${apiUrl}/allChapter/${enc_exam}/${enc_subject}`);
    }

    stepOneUploadPdf(data) {
        return this.postReq(`${apiUrl}/stepOneUploadPdf/`, data);
    }

    stepThreeUploadPdf(data) {
        return this.postReq(`${apiUrl}/stepThreeUploadPdf/`, data);
    }

    stepFourUploadPdf(data) {
        return this.postReq(`${apiUrl}/stepFourUploadPdf/`, data);
    }

    addStudent(data) {
        return this.postReq(`${apiUrl}/addStudent`, data);
    }

    verifyStudent(data) {
        return this.postReq(`${apiUrl}/verifyStudent`, data);
    }

    getStudentById(stud_id) {
        const enc_stud_id = this.cryptopService.setParam(stud_id);
        return this.getReq(`${apiUrl}/studentById/${enc_stud_id}`);
    }

    verifyBeforeEditStudent(data) {
        return this.postReq(`${apiUrl}/verifyBeforeEditStudent`, data);
    }

    editStudent(data) {
        return this.postReq(`${apiUrl}/editStudent`, data);
    }

    getInstitutionCount() {
        return this.getReq(`${apiUrl}/institutionCount`);
    }

    resendMail(userId) {
        const enc_userId = this.cryptopService.setParam(userId);
        return this.getReq(`${apiUrl}/resendMail/${enc_userId}`);
    }

    getStudentExams(stud_email) {
        return this.getReq(`${apiUrl}/user/exams/${stud_email}`);
    }

    examSummaryData(stud_email, exam_id, test_slug_name) {
        return this.getReq(`${apiUrl}/examSummaryData/${stud_email}/${exam_id}/${test_slug_name}`);
    }

    getSubject(exam_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.getReq(`${apiUrl}/subject/${enc_exam_id}`);
    }

    getAllSubject() {
        return this.getReq(`${apiUrl}/allSubject`);
    }

    getExam(exam_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.getReq(`${apiUrl}/singleExam/${enc_exam_id}`);
    }

    getPerformanceTest(stud_email, data) {
        return this.postReq(`${apiUrl}/getPerformanceTest/${stud_email}`, data);
    }

    getStudentTestSummaries(stud_email, data) {
        return this.postReq(`${apiUrl}/test_summaries/${stud_email}`, data);
    }

    examSummaryForTest(test_summary_id) {
        const enc_test_summary_id = this.cryptopService.setParam(test_summary_id);
        return this.getReq(`${apiUrl}/examSummaryForTest/${enc_test_summary_id}`);
    }

    logsForTestResult(test_summary_id) {
        const enc_test_summary_id = this.cryptopService.setParam(test_summary_id);
        return this.getReq(`${apiUrl}/logsForTestResult/${enc_test_summary_id}`);
    }

    gettestType() {
        return this.getReq(`${apiUrl}/gettestType`);
    }

    getExamByInsitutionId() {
        return this.getReq(`${apiUrl}/examByInsitutionId`);
    }

    removePdf(data) {
        return this.putReq(`${apiUrl}/removePdf`, data);
    }

    getpdftestListByStatus(status, data) {
        const enc_status = this.cryptopService.setParam(status);
        return this.postReq(`${apiUrl}/pdftestListByStatus/${enc_status}`, data);
    }

    updatePdfTestStatus(data) {
        return this.putReq(`${apiUrl}/updatePdfTestStatus`, data);
    }

    getsubByInsitutionId() {
        return this.getReq(`${apiUrl}/subByInsitutionId`);
    }

    getPdftestById(pdf_test_id) {
        const enc_spdf_test_id = this.cryptopService.setParam(pdf_test_id);
        return this.getReq(`${apiUrl}/pdftestById/${enc_spdf_test_id}`);
    }

    deletePdfTest(data) {
        return this.deleteReq(`${apiUrl}/deletePdfTest`, data);
    }

    getScoreAndTime(data) {
        return this.postReq(`${apiUrl}/getScoreAndTime`, data);
    }

    getpdfTestattempted(data) {
        return this.postReq(`${apiUrl}/pdfTestattempted`, data);
    }

    getPdfTestBySummId(pdf_test_summ_id) {
        const enc_pdf_test_summ_id = this.cryptopService.setParam(pdf_test_summ_id);
        return this.getReq(`${apiUrl}/pdfTestBySummId/${enc_pdf_test_summ_id}`);
    }

    stepOneProfTest(data) {
        return this.postReq(`${apiUrl}/stepOneProfTest/`, data);
    }

    stepThreeProfTest(data) {
        return this.postReq(`${apiUrl}/stepThreeProfTest/`, data);
    }

    getConcept() {
        return this.getReq(`${apiUrl}/getConcept`);
    }

    stepTwoAddQuestion(data) {
        return this.postReq(`${apiUrl}/stepTwoAddQuestion/`, data);
    }

    getProftestById(prof_test_id) {
        const enc_prof_test_id = this.cryptopService.setParam(prof_test_id);
        return this.getReq(`${apiUrl}/proftestById/${enc_prof_test_id}`);
    }

    chooseFromLibrary(exam_id, sub_id, test_type_id, prof_test_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        const enc_sub_id = this.cryptopService.setParam(sub_id);
        const enc_test_type_id = this.cryptopService.setParam(test_type_id);
        const enc_prof_test_id = this.cryptopService.setParam(prof_test_id);
        return this.getReq(`${apiUrl}/chooseFromLibrary/${enc_exam_id}/${enc_sub_id}/${enc_test_type_id}/${enc_prof_test_id}`);
    }

    sendMessageToStudent(data) {
        return this.postReq(`${apiUrl}/sendMessageToStudent/`, data);
    }

    sendTestMessageToStudent(data) {
        return this.postReq(`${apiUrl}/sendTestMessageToStudent/`, data);
    }

    stepTwochooseFromLibrary(data) {
        return this.postReq(`${apiUrl}/stepTwochooseFromLibrary/`, data);
    }

    removeQuestion(data) {
        return this.deleteReq(`${apiUrl}/removeQuestion`, data);
    }

    getQuestionByMetaId(question_meta_id) {
        const enc_question_meta_id = this.cryptopService.setParam(question_meta_id);
        return this.getReq(`${apiUrl}/getQuestionByMetaId/${enc_question_meta_id}`);
    }

    stepTwoEditQuestion(data) {
        return this.postReq(`${apiUrl}/stepTwoEditQuestion/`, data);
    }

    getProfScoreAndTime(data) {
        return this.postReq(`${apiUrl}/getProfScoreAndTime`, data);
    }

    getprofTestattempted(data) {
        return this.postReq(`${apiUrl}/profTestattempted`, data);
    }

    getManualtestById(prof_test_id) {
        const enc_prof_test_id = this.cryptopService.setParam(prof_test_id);
        return this.getReq(`${apiUrl}/manualtestById/${enc_prof_test_id}`);
    }

    getProfTestBySummId(prof_test_summ_id) {
        const enc_prof_test_summ_id = this.cryptopService.setParam(prof_test_summ_id);
        return this.getReq(`${apiUrl}/profTestBySummId/${enc_prof_test_summ_id}`);
    }

    getProfQuestionData(data) {
        return this.postReq(`${apiUrl}/profQuestionData`, data);
    }

    postReq(url, data): Observable<any> {
        return this.http.post(url, data, httpOptions).pipe(
            map(this.extractData),
            catchError(this.handleError),
        );
    }

    getReq(url): Observable<any> {
        return this.http.get(url, httpOptions).pipe(
            map(this.extractData),
            catchError(this.handleError),
        );
    }

    putReq(url, data): Observable<any> {
        return this.http.put(url, data, httpOptions).pipe(
            map(this.extractData),
            catchError(this.handleError),
        );
    }

    deleteReq(url, data): Observable<any> {
        httpOptions['body'] = data;
        return this.http.delete(url, httpOptions).pipe(
            map(this.extractData),
            catchError(this.handleError),
        );
    }
}
