import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Injectable()
export class Globals {
    default: any;
    sidebar_status: any = true;
    header_status: any = true;
    private messageSource = new BehaviorSubject(this.default);
    currentMessage = this.messageSource.asObservable();

    constructor() { }

    loggedIn(value: any) {
        this.messageSource.next(value);
        localStorage.setItem('isLoggedIn', value);
    }

    headerStatus(value: any) {
        this.messageSource.next(value);
        this.header_status = value;
    }

    sidebarStatus(value: any) {
        this.messageSource.next(value);
        this.sidebar_status = value;
    }

    convertTimeToDateTime(d) {
        const date = d.split(':');
        const hours = date[0];
        const minutes = date[1];
        const sec = date[2];
        const newDate = new Date();
        newDate.setHours(hours, minutes,  sec);
        return newDate;
    }

    decodeParam(param) {
        return JSON.parse(atob(param));
    }

    encodeParam(obj) {
        return btoa(JSON.stringify(obj));
    }

    convertTime(time) {
        let hr = '00';
        let min = '00';
        let secs = '00';
        if (time) {
            hr = (time.split(':')[0] > 0) ? time.split(':')[0] + ' Hrs ' : '';
            min = (time.split(':')[1] > 0 || time.split(':')[0] > 0) ? time.split(':')[1] + ' Mins ' : '';
            secs = (time.split(':')[2] > 0 || time.split(':')[1] > 0 || time.split(':')[0] > 0) ? time.split(':')[2] + ' Secs ' : '';
        }
        return hr + ' ' + min + ' ' + secs;
    }

    convertTimetoSeconds(time) {
        const hours = time.split(':')[0] * 3600;
        const mins = time.split(':')[1]  * 60;
        const sec = time.split(':')[2] * 1;
        return Math.floor(hours + mins + sec);
    }

    convertSecToTime(given_seconds) {
        let timeString = '0';
        const hours = Math.floor(given_seconds / 3600);
        const minutes = Math.floor((given_seconds - (hours * 3600)) / 60);
        const seconds = (given_seconds - (hours * 3600) - (minutes * 60)).toFixed(0);
        if (hours !== 0 && minutes !== 0 && seconds !== '0') {
            timeString = hours.toString() + ' hr ' +
                minutes.toString().padStart(2, '0') + ' mins ' +
                seconds.toString().padStart(2, '0') + ' sec';
        } else if (hours !== 0 && minutes !== 0) {
            timeString = hours.toString() + ' hr ' +
                minutes.toString().padStart(2, '0') + ' mins ';
        } else if (minutes !== 0 && seconds !== '0') {
            timeString = minutes.toString().padStart(2, '0') + ' mins ' +
                seconds.toString().padStart(2, '0') + ' sec';
        } else if (minutes !== 0) {
            timeString = minutes.toString().padStart(2, '0') + ' mins ';
        } else if (seconds !== '0') {
            timeString = seconds.toString().padStart(2, '0') + ' sec';
        }
        return timeString;
    }

    // Form atleast one value selected
    atLeastOneValidator = () => {
        return (group: FormGroup) => {
            // Check that every element in array is null
            if (Object.values(group.value).every(value => value === null)) {
                return { message: 'Please select at least one value' };
            }
            return null;
        };
    }
}
