import { Injectable } from '@angular/core';

import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { Globals } from '../global';
import { tap } from 'rxjs/operators';


@Injectable({
    providedIn: 'root',
})
export class AuthService {
    isLoggedIn: boolean = false;
    token: NbAuthJWTToken;

    constructor(
        private authService: NbAuthService,
        public globals: Globals,
    ) {
        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                this.token = token;
                this.isLoggedIn = token.isValid();
                this.globals.loggedIn(this.isLoggedIn);
                return this.isLoggedIn;
            });
    }

    checkLogin() {
        return this.authService.isAuthenticated()
            .pipe(tap(authenticated => {
                this.globals.loggedIn(authenticated);
                return authenticated;
            }));
    }

}
